import styled from "styled-components";
import { media } from "../../utils/styled/responsive";

export const Container = styled.div`
  display: grid;
  place-items: center;
  gap: 120px;
  width: 100vw;
`;

export const Content = styled.div`
  display: grid;

  gap: 80px;
  padding: 0 24px;

  ${media.tablet} {
    padding: unset;
    width: 60vw;
    gap: 108px;
  }

  ${media.desktop} {
    width: 40vw;
    gap: 120px;
    max-width: 800px;
  }
`;
