import React, { FC } from "react";
import { useInView } from "react-intersection-observer";

import { ItemWrapperVariants, OverlayVariants } from "./ItemReveal.animations";
import { ItemRevealContainer, ItemWrapper, Overlay } from "./ItemReveal.styles";

export interface ItemRevealProps {
  transformOrigin: "left" | "right" | "top" | "bottom";
  children: React.ReactNode;
}

const ItemReveal: FC<ItemRevealProps> = ({
  children,
  transformOrigin = "bottom",
  ...rest
}) => {
  const { ref, inView } = useInView();

  return (
    <ItemRevealContainer ref={ref} {...rest}>
      <Overlay
        inView={inView}
        transformOrigin={transformOrigin}
        initial="initial"
        animate={inView ? "hide" : "show"}
        variants={OverlayVariants}
      />
      <ItemWrapper
        initial="initial"
        animate={inView ? "visible" : "outside"}
        variants={ItemWrapperVariants}
      >
        {children}
      </ItemWrapper>
    </ItemRevealContainer>
  );
};
export default ItemReveal;
