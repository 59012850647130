import { graphql } from "gatsby";
import React from "react";
import FadeInOut from "../../components/Animations/FadeInOut/FadeInOut";
import Shader from "../../components/Shader/Shader";
import LineReveal from "../../components/Text/LineReveal/LineReveal";
import { Container, Content } from "./CardPage.styles";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";

function CardPage({ data }) {
  const {
    paragraph1,
    paragraph2,
    referenceTitle,
    referenceUrl,
    title,
    title1,
    title2,
    desktopImage,
    mobileImage,
  } = data.contentfulCardPage;

  return (
    <FadeInOut>
      <Container>
        <Shader />
        <Header
          title={title}
          desktopImage={desktopImage}
          mobileImage={mobileImage}
        />
        <Content>
          <LineReveal center text={title1} renderAs="h2" />
          <LineReveal
            center
            longText
            text={paragraph1.paragraph1}
            renderAs="p"
          />
          {title2 && paragraph2 && (
            <>
              <LineReveal center text={title2} renderAs="h2" />
              <LineReveal
                longText
                center
                text={paragraph2.paragraph2}
                renderAs="p"
              />
            </>
          )}
        </Content>
      </Container>
      <Footer referenceTitle={referenceTitle} referenceUrl={referenceUrl} />
    </FadeInOut>
  );
}

export default React.memo(CardPage);

export const data = graphql`
  query CardPage($slug: String, $locale: String) {
    contentfulCardPage(url: { eq: $slug }, node_locale: { eq: $locale }) {
      title
      title1
      title2
      url
      paragraph1 {
        paragraph1
      }
      paragraph2 {
        paragraph2
      }
      desktopImage {
        gatsbyImageData
        title
        description
      }
      mobileImage {
        gatsbyImageData(quality: 90)
        title
        description
      }
      referenceTitle
      referenceUrl
    }
  }
`;
