export const slideIn = {
  initial: {
    opacity: 0,
    y: "1em",
  },
  slideIn: ({ i, delay = 0, duration }) => ({
    opacity: [null, 1],
    y: "0em",
    transition: {
      delay: i * 0.2 + delay,
      duration: duration ? duration : 1,
      ease: "easeInOut",
    },
  }),
  revealExit: {
    opacity: 0,
    y: "-1em",
    transition: {
      duration: 0.4,
      ease: "easeOut",
    },
  },
};
