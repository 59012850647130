import styled, { css } from "styled-components";
import { m } from "framer-motion";

import { slideIn } from "./LetterReveal.animations";
import { media } from "../../../utils/styled/responsive";

interface LetterWrapperProps {
  center?: boolean;
}

export const LettersContainer = styled.div`
  height: min-content;
  width: auto;
  white-space: pre-wrap;
`;

export const LetterWrapper = styled.span<LetterWrapperProps>`
  overflow: hidden;
  display: inline-block;
  pointer-events: none;

  ${media.tablet} {
    ${({ center }) =>
      center &&
      css`
        text-align: center;
      `}
  }
`;

export const Letter = styled(m.span)`
  transform-origin: left;
  display: inline-block;
  will-change: transform;
`;

Letter.defaultProps = {
  variants: slideIn,
  initial: "initial",
};
