import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { FC } from "react";
import ItemReveal from "../../../components/ItemReveal/ItemReveal";
import { Mobile, Desktop } from "../../../components/MediaQueries/MediaQueries";
import LineReveal from "../../../components/Text/LineReveal/LineReveal";
import useViewportSize from "../../../hooks/useViewportSize";

import * as Styled from "./Header.styles";

export interface HeaderProps {
  title: string;
  desktopImage: any;
  mobileImage: any;
}

const Header: FC<HeaderProps> = ({ title, desktopImage, mobileImage }) => {
  const { size } = useViewportSize({
    triggerOnce: true,
  });

  return (
    <Styled.Container vh={size.height}>
      <LineReveal center triggerOnce={true} text={title} renderAs="h1" />
      <Styled.ImageContainer>
        <ItemReveal transformOrigin="top">
          <Mobile>
            <GatsbyImage image={getImage(mobileImage)} alt="xd" />
          </Mobile>
          <Desktop>
            <GatsbyImage image={getImage(desktopImage)} alt="xd" />
          </Desktop>
        </ItemReveal>
      </Styled.ImageContainer>
    </Styled.Container>
  );
};
export default Header;
