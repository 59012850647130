import { m } from "framer-motion";
import styled from "styled-components";

interface ContainerProps {
  vh: number;
}

export const Container = styled.div<ContainerProps>`
  height: ${({ vh }) => (vh ? `${vh}px` : "100vh")};
  width: 100vw;
  display: grid;
  place-items: center;
  grid-template-rows: 1fr auto;
  max-width: 2000px;
`;

export const ImageContainer = styled(m.div)`
  height: auto;
`;
