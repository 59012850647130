import React, { ElementType, FC, useLayoutEffect, useState } from "react";
import { m } from "framer-motion";
import { useInView } from "react-intersection-observer";

import { splitByLetters } from "../utils/splitByLetters";
import useViewportSize from "../../../hooks/useViewportSize";

import * as Styled from "./LetterReveal.styles";

export interface LetterRevealProps {
  text: string;
  triggerOnce?: boolean;
  center?: boolean;
  renderAs?: ElementType;
  duration?: number;
  delay?: number;
  cName?: string;
}

const LetterReveal: FC<LetterRevealProps> = ({
  text = "Some Random Text",
  triggerOnce = false,
  center = false,
  renderAs = "p",
  delay = 0,
  cName = "",
  duration = 0.8,
  ...rest
}) => {
  const [letters, setLetters] = useState([]);

  const { size } = useViewportSize();

  useLayoutEffect(() => {
    setLetters(splitByLetters(text));
  }, [text, size.width]);

  const { ref, inView } = useInView({
    triggerOnce: triggerOnce,
  });

  return (
    <Styled.LettersContainer ref={ref} aria-label={text} {...rest}>
      {letters.map((char, index) => (
        <Styled.LetterWrapper key={index + char} center={center}>
          <Styled.Letter
            aria-hidden="true"
            //@ts-ignore
            as={m[renderAs]}
            className={cName}
            animate={inView ? "slideIn" : "initial"}
            custom={{ i: index, delay: delay, duration: duration }}
            exit={inView && "revealExit"}
          >
            {char}
          </Styled.Letter>
        </Styled.LetterWrapper>
      ))}
    </Styled.LettersContainer>
  );
};
export default LetterReveal;
