import styled from "styled-components";
import { motion } from "framer-motion";

import { sharedValues } from "../../utils/styled/sharedValues";

interface OverlayProps {
  inView: boolean;
  transformOrigin: "left" | "right" | "top" | "bottom";
}

export const ItemRevealContainer = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  overflow: hidden;
`;

export const Overlay = styled(motion.div)<OverlayProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: 110%;
  width: 110%;
  background-color: ${sharedValues.colors.black[0]};
  z-index: 1;
  transform-origin: ${({ transformOrigin }) => transformOrigin};
  will-change: transform, opacity;
`;

export const ItemWrapper = styled(motion.div)``;
