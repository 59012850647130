export const OverlayVariants = {
  initial: { scaleY: 1 },
  show: { scaleY: 1, transition: { duration: 1.6, ease: "easeInOut" } },
  hide: { scaleY: 0, transition: { duration: 1.6, ease: "easeInOut" } },
};

export const ItemWrapperVariants = {
  initial: { scale: 1.2 },
  visible: { scale: 1, transition: { duration: 1.6, ease: "easeInOut" } },
  outside: { scale: 1.2, transition: { duration: 1.6, ease: "easeInOut" } },
};
